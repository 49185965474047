
// ==========This code is normal dropdown functionality====================

// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

// const img = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg';

// const Header = () => {
//   const location = useLocation();
//   const [activeLink, setActiveLink] = useState(location.pathname);
//   const [expanded, setExpanded] = useState(false); 

//   useEffect(() => {
//     setActiveLink(location.pathname);
//   }, [location.pathname]);

//   return (
//     <div className="header-container">
//       <Navbar fixed="top" expand="lg" className="custom-navbar" expanded={expanded}>
//         <a href="/">
//           <img
//             className="companylogo"
//             src={img}
//             alt="Company Logo"
//           />
//         </a>
//         <Navbar.Toggle 
//           aria-controls="basic-navbar-nav" 
//           className="navbarstyle"
//           onClick={() => setExpanded(expanded ? false : "expanded")}
//         >
//            {/* Change icon based on expanded state */}
//            <span style={{ fontSize: '25px' }}> {/* Increased icon size and padding */}
//             {expanded ? '✕' : '☰'} {/* Unicode characters for 'X' and hamburger icon */}
//           </span>
//           {/* {expanded ? (
//             <span>&#x2715;</span>  
//           ) : (
//             <span style={{fontSize:'30px'}}>&#9776;</span>  
//           )} */}
//         </Navbar.Toggle>
//         <Navbar.Collapse style={{ marginLeft: '40px' }} id="basic-navbar-nav">
//           <Nav className="me-auto gap-4 gapstyle">
//             <NavDropdown title="Services" id="basic-nav-dropdown" className={`${activeLink.startsWith('/Services') ? 'active' : ''}`} style={{ fontSize: '17px', fontWeight: '400', fontFamily: 'Plus Jakarta Sans, sans-serif' }}>
//               <NavDropdown.Item href="/service/website-design-development-solutions" className={activeLink === '/Webdev' ? 'active' : ''}>Website Development</NavDropdown.Item>
//               <NavDropdown.Item href="/service/mobile-app-design-development-solutions" className={activeLink === '/Mobiledev' ? 'active' : ''}>Mobile Development</NavDropdown.Item>
//               <NavDropdown.Item href="/service/social-media-marketing" className={activeLink === '/Socialmedia' ? 'active' : ''}>Social Media</NavDropdown.Item>
//               <NavDropdown.Item href="/services/digital-marketing" className={activeLink === '/Digitalmarketing' ? 'active' : ''}>Digital Marketing</NavDropdown.Item>
//               <NavDropdown.Item href="/services/performance-marketing" className={activeLink === '/Performancemarketing' ? 'active' : ''}>Performance Marketing</NavDropdown.Item>
//               <NavDropdown.Item href="/services/graphic-desinging" className={activeLink === '/Graphicdesigning' ? 'active' : ''}>Graphic Designing</NavDropdown.Item>
//               <NavDropdown.Item href="/service/search-engine-optimization-seo" className={activeLink === '/Seo' ? 'active' : ''}>SEO</NavDropdown.Item>
//             </NavDropdown>
//             <Nav.Link href="/our-completed-projects" className={`navlinkstyle  ${activeLink === '/our-completed-projects' ? 'active' : ''}`}>Our Projects</Nav.Link>
//             <Nav.Link href="https://blog.datametricks.com/" className={`navlinkstyle  ${activeLink === 'https://blog.datametricks.com/' ? 'active' : ''}`}>Blogs</Nav.Link>
//             <Nav.Link href="/about-us" className={`navlinkstyle  ${activeLink === '/about-us' ? 'active' : ''}`}>About Us</Nav.Link>
//             <Nav.Link href="/contact-us" className={`navlinkstyle  ${activeLink === '/contact-us' ? 'active' : ''}`}>Contact Us</Nav.Link>
//             <Nav.Link href='/ai-ml'>
//               <div style={{ position: 'relative' }}>
//                 <span className='plus fw-600 fs-18' style={{ color: ' rgba(0, 14, 217, 1), rgba(0, 15, 104, 1)' }}>AI & ML</span>
//                 <div style={{ padding: '3px 15px',   background: 'linear-gradient(to left, rgba(96, 20, 165, 1), rgba(90, 20, 170, 1), rgba(129, 18, 145, 1), rgba(132, 18, 143, 1), rgba(248, 0, 66, 1))', color: 'white', fontSize: '13px', fontWeight: '500', borderRadius: '5px', position: 'absolute', left: '70px', bottom: '15px', animation: 'blink 1s infinite' }}>
//                   New
//                 </div>
//               </div>
//             </Nav.Link>
//           </Nav>
//           <Nav className="ms-auto">
//             <Nav.Link href="/contact-us" className="align-items-end">
//               <button type="button" className="btn btn-sm text-white plus fs-18" style={{ boxShadow: 'none', border: 'none', paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '7px', backgroundColor: 'rgba(5, 5, 5, 1)', fontWeight: '600' }}>
//                 Let’s Talk
//               </button>
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//     </div>
//   );
// };

// export default Header;

// ==========This code is normal dropdown functionality END====================



import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const Header = () => {


const img = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg';
const newheaderimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newHeaderimg1.svg";
const newheaderimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg2.svg";
const newheaderimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg3.svg";
const newheaderimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg4.svg";
const newheaderimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg5.svg";
const newheaderimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg6.svg";
const newheaderimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg7.svg";
const newheaderimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg8.svg";
const newheaderimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg9.svg";
const newheaderimg10 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newheaderimg10.svg";
const upheadarrow = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/headuparrow.svg";
const downheadarrow = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/headdownarrow.svg";

  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [expanded, setExpanded] = useState(false);
  // const [showDropdownContent, setShowDropdownContent] = useState(false);
  const [activeButton, setActiveButton] = useState('digitalMarketing');


  useEffect(() => {
    setActiveLink(location.pathname);
   
  }, [location.pathname]);

  const handleDropdownToggle = (isOpen) => {
    // setShowDropdownContent(isOpen);
    if(isOpen===true){
      setIsFocused(true);
      setIsOpen(true);
    }
    else{
      setIsFocused(false);
      // setIsOpen(false);
    }
    
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
    

  };
 
    const [isFocused, setIsFocused] = useState(false); // State to track focus
    const [isOpenDrop, setIsOpen] = useState(false); // State to track focus
  
   


  return (
    <div>
      
    <div className="header-container " >
      <Navbar fixed="top" expand="lg" className="custom-navbar" expanded={expanded}>
        <a href="/">
          <img className="companylogo" src={img} alt="Company Logo" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null}/>
        </a>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav"
          className="navbarstyle"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        >
          <span style={{ fontSize: '25px' }}>
            {expanded ? '✕' : '☰'}
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse style={{ marginLeft: '40px' }} id="basic-navbar-nav">
          <Nav className="me-auto gap-4 gapstyle">

            {/* Solutions menu title */}
            <div className='d-none d-sm-none d-lg-none d-md-none'>
            <Nav.Link
              className={`navlinkstyle ${activeLink === '' ? 'active' : ''}`}
              onMouseEnter={() => handleDropdownToggle(true)}
            
      
            >
      Solutions <span>{isFocused ? <img src={upheadarrow} alt='' style={{height:'13px',width:'13px'}} /> : <img src={downheadarrow} alt='' style={{height:'13px',width:'13px'}}/> }</span>
      </Nav.Link>
            </div>

          
            <div className='d-block d-lg-none d-md-none d-sm-block'>
           <NavDropdown title="Solutions" id="basic-nav-dropdown" className={`${activeLink.startsWith('/Solutions') ? 'active' : ''}`} style={{ fontSize: '18px', fontFamily: 'Plus Jakarta Sans, sans-serif' }}>
               <NavDropdown.Item href="/service/website-design-development-solutions" className={activeLink === '/Webdev' ? 'active' : ''}>Website Development</NavDropdown.Item>
              <NavDropdown.Item href="/service/mobile-app-design-development-solutions" className={activeLink === '/Mobiledev' ? 'active' : ''}>Mobile Development</NavDropdown.Item>
               <NavDropdown.Item href="/service/social-media-marketing" className={activeLink === '/Socialmedia' ? 'active' : ''}>Social Media</NavDropdown.Item>
               <NavDropdown.Item href="/services/digital-marketing" className={activeLink === '/Digitalmarketing' ? 'active' : ''}>Digital Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/services/performance-marketing" className={activeLink === '/Performancemarketing' ? 'active' : ''}>Performance Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/services/graphic-desinging" className={activeLink === '/Graphicdesigning' ? 'active' : ''}>Graphic Designing</NavDropdown.Item>
               <NavDropdown.Item href="/service/search-engine-optimization-seo" className={activeLink === '/Seo' ? 'active' : ''}>SEO</NavDropdown.Item>
           </NavDropdown></div>

              <Nav.Link href="/our-completed-projects" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`navlinkstyle ${activeLink === '/our-completed-projects' ? 'active' : ''}`}>Our Projects</Nav.Link>
            <Nav.Link href="https://blog.datametricks.com/" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null}
              className={`navlinkstyle ${activeLink === 'https://blog.datametricks.com/' ? 'active' : ''}`}>Blogs</Nav.Link>
            <Nav.Link href="/about-us" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`navlinkstyle ${activeLink === '/about-us' ? 'active' : ''}`}>About Us</Nav.Link>
            <Nav.Link href="/contact-us" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} className={`navlinkstyle ${activeLink === '/contact-us' ? 'active' : ''}`}>Contact Us</Nav.Link>
            <Nav.Link href='/ai-ml' onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null} >
              <div style={{ position: 'relative' }}>
                <span className='plus fw-600 fs-18 gradient-text-header'>AI & ML</span>
                <div style={{ padding: '3px 15px', background: 'linear-gradient(to right, #04D9BE 20%, #BF64FF 100% ,#058AF7 100%,#058AF7 0%)', color: 'rgba(41, 0, 125, 1)', fontSize: '13px', fontWeight: '500', borderRadius: '5px', position: 'absolute', left: '70px', bottom: '15px', }}>
                  New
                </div>
              </div>
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link href="/contact-us" className="align-items-end" onMouseEnter={() => (isFocused)?handleDropdownToggle(false):null}>
              <button type="button" className="btn btn-sm text-white plus fs-18" style={{ boxShadow: 'none', border: 'none', paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '7px', backgroundColor: 'rgba(5, 5, 5, 1)', fontWeight: '600' }}>
                Let’s Talk
              </button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

      
      </Navbar>



    
    </div>
    

      {/* Full-width blue background container */}
      {isOpenDrop&& (
     
              <div 

           
                // className="full-width-container d-none d-sm-none d-lg-block d-md-block"                      position-fixed                                                              position-fixed
                // className={isFocused ? 'slide-down full-width-container d-none d-sm-none d-lg-block d-md-block   position-fixed'  : 'slide-up full-width-container d-none d-sm-none d-lg-block d-md-block  position-fixed'}
                // onMouseEnter={() => handleDropdownToggle(true)} 
                onMouseLeave={() => handleDropdownToggle(false)} 
              >
    
                <div className='container '>
               <div className='d-flex gap-3'>
            
                  <div>
                    <button
                      type="button"
                      className="fw-400 fs-18 plus btn-sm px-3 py-1"
                      style={{

                        color: activeButton === 'digitalMarketing' ? 'rgba(255, 255, 255, 1)' : 'rgba(109, 109, 109, 1)', // Text color changes to white on click
                        borderRadius: '34px',
                        border: activeButton === 'digitalMarketing' ? ' rgba(109, 109, 109, 1)' : '1px solid rgba(109, 109, 109, 1)',
                        backgroundColor: activeButton === 'digitalMarketing' ? 'rgba(0, 110, 205, 1)' : 'transparent',
                        fontSize: activeButton === 'digitalMarketing' ? '19px' : '18px', 

                      }}
                      onClick={() => handleButtonClick('digitalMarketing')}

                     
                    >
                      Digital Marketing
                    </button>
                  </div>
                 
                  <div>
                    <button
                      type="button"
                      className="fw-400 fs-18 plus btn-sm px-3 py-1"
                      style={{

                        color: activeButton === 'webDevelopment' ? 'rgba(255, 255, 255, 1)' : 'rgba(109, 109, 109, 1)', // Text color changes to white on click
                        borderRadius: '34px',
                         border: activeButton === 'webDevelopment' ? ' rgba(109, 109, 109, 1)' : '1px solid rgba(109, 109, 109, 1)',
                        backgroundColor: activeButton === 'webDevelopment' ? 'rgba(0, 110, 205, 1)' : 'transparent',
                        fontSize: activeButton === 'webDevelopment' ? '19px' : '18px', 

                       
                      }}
                      onClick={() => handleButtonClick('webDevelopment')}

                    
                    >
                      Web Development
                    </button>
                   
                  </div>
                
               
             </div>
           

                      
                
                  {activeButton === 'digitalMarketing' && (
                    <div>
                         <div>   
                 {/* <p className="mt-3 fs-16 fw-400 plus" style={{color:'rgba(0, 0, 0, 0.6)'}}>Lorem ipsum dolor sit amet consectetur. Molestie tincidunt posuere pellentesque sit dignissim et nisl morbi velit. Orci morbi natoque nulla pla</p> */}

               <div className='row mt-3'>
                <div className='col-lg-4 '>

      


         <a href='/services/digital-marketing' style={{textDecoration:'none'}} > <div className='mt-2 header-shadow p-2 ' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px',}}>

                 <div className='d-flex gap-3 align-items-center' >

                      <div>
                        <img src={newheaderimg1} alt=''  />
                      </div>
                   
                      <div>
                         <p className='fw-600 plus fs-17 mb-0 ' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Digital Marketing</p>
                         <p className='fs-14 plus' style={{color:'rgba(53, 53, 53, 0.72)'}}>Accelerate Your Online Growth with Expert Digital Marketing Solutions</p>
                       </div>
                 </div>
                 </div></a>
                 </div>

                 <div className='col-lg-4'>

    <a href='/service/search-engine-optimization-seo' style={{textDecoration:'none'}} ><div className='mt-2 header-shadow p-2 ' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
    <div>
       <img src={newheaderimg2} alt='' />
     </div>
     <div >
        <h1 className='fw-600 plus fs-17 mb-0 ' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>SEO  </h1>
        <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Boost Your Search Rankings and Drive Organic Traffic</p>
      </div>
</div>
</div></a>
</div>

<div className='col-lg-4'>

<a href='/service/social-media-marketing' style={{textDecoration:'none'}} ><div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
    <div>
       <img src={newheaderimg3} alt=''  />
     </div>
     <div >
        <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Social Media Marketing</h1>
        <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Amplify Your Brand's Voice with Targeted Social Media Campaigns </p>
      </div>
</div>
</div></a>
</div>


<div className='row justify-content-center mt-3'>
                <div className='col-lg-4'>

                <a href='/services/performance-marketing' style={{textDecoration:'none'}} ><div className='mt-2 header-shadow p-2 ' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

                 <div className='d-flex gap-3 align-items-center'>
                     <div>
                        <img src={newheaderimg4} alt=''  />
                      </div>
                      <div >
                         <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Performance Marketing</h1>
                         <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Data-Driven Strategies to Maximize ROI and Lead Generation </p>
                       </div>
                 </div>
                 </div></a>
                 </div>
                 <div className='col-lg-4'>

                 <a href='/services/graphic-desinging' style={{textDecoration:'none'}} ><div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>

<div className='d-flex gap-3 align-items-center'>
    <div>
       <img src={newheaderimg5} alt='' />
     </div>
     <div >
        <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Graphic Designing</h1>
        <p className='fs-14 plus ' style={{color:'rgba(53, 53, 53, 0.72)'}}>Crafting Visual Masterpieces to Elevate Your Brand Identity</p>
      </div>
</div>
</div></a>
</div>
            </div>

                 </div>
             
              </div>
                    </div>
                  )}

                
                  {activeButton === 'webDevelopment' && (
                    <div>
                        <div>   
                      {/* <p className="mt-3 fs-16 fw-400 plus" style={{color:'rgba(0, 0, 0, 0.6)'}}>Lorem ipsum dolor sit amet consectetur. Molestie tincidunt posuere pellentesque sit dignissim et nisl morbi velit. Orci morbi natoque nulla pla</p> */}
     
                    <div className='row mt-3'>
                     <div className='col-lg-4'>
     
                     <a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}} >  <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>
     
                      <div className='d-flex gap-3 align-items-center'>
                          <div>
                             <img src={newheaderimg6} alt='' />
                           </div>
                           <div >
                              <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Custom Website</h1>
                              <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Tailored Digital Solutions to Meet Your Unique Business Needs</p>
                            </div>
                      </div>
                      </div></a>
                      </div>
     
                      <div className='col-lg-4'>
     
       <a href='/service/website-design-development-solutions' style={{textDecoration:'none'}} >    <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>
     
     <div className='d-flex gap-3 align-items-center'>
         <div>
            <img src={newheaderimg7} alt=''   />
          </div>
          <div >
             <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Web Development</h1>
             <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Designing Cutting-Edge Websites that Drive Engagement and Growth</p>
           </div>
     </div>
     </div></a>
     </div>
     
     <div className='col-lg-4'>
     
     <a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}} >  <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>
     
     <div className='d-flex gap-3 align-items-center'>
         <div>
            <img src={newheaderimg8} alt=''  />
          </div>
          <div >
             <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>Mobile App Development</h1>
             <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Building Intuitive and High-Performance Mobile Apps for All Platforms</p>
           </div>
     </div>
     </div></a>
     </div>
     
     
     <div className='row justify-content-center mt-3'>
                     <div className='col-lg-4'>
     
   <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}} >  <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>
     
                      <div className='d-flex gap-3 align-items-center'>
                          <div>
                             <img src={newheaderimg9} alt=''   />
                           </div>
                           <div >
                              <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>E Commerce  Website</h1>
                              <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Empowering Your Online Store with Seamless Ecommerce Development</p>
                            </div>
                      </div>
                      </div></a>
                      </div>
                      <div className='col-lg-4'>
     
       <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}} >   <div className='mt-2 header-shadow p-2' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'5px'}}>
     
     <div className='d-flex gap-3 align-items-center'>
         <div>
            <img src={newheaderimg10} alt='' />

          </div>
          <div >
             <h1 className='fw-600 plus fs-17 mb-0' style={{color:'rgba(53, 53, 53, 1)',paddingTop:'13px'}}>CMS Website</h1>
             <p className='fs-14 plus pt-1' style={{color:'rgba(53, 53, 53, 0.72)'}}>Efficient Content Management Solutions for Dynamic and Scalable Websites</p>
           </div>
     </div>
     </div></a>
     </div>
                 </div>
     
                      </div>
                  
                   </div>
                    </div>
                  )}


            </div>
              </div>
            )}




    </div>
  );
};

export default Header;



