

// import React, { useState, useEffect, useRef  } from 'react';

// import { FaArrowRight } from 'react-icons/fa';
// import { FaArrowLeft } from 'react-icons/fa';


// const Subheader = ({ headings = [] }) => {
//     const [activeTab, setActiveTab] = useState(headings[0]?.head); 
//     const scrollRef = useRef(null);

//   const handleScrollRight = () => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollBy({
//         left: 200, 
//         behavior: 'smooth',
//       });
//     }
//   };

//   const handleScrollLeft = () => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollBy({
//         left: -200, 
//         behavior: 'smooth',
//       });
//     }
//   };

//   const handleScroll = (event) => {
//     event.preventDefault(); 
//     scrollRef.current.scrollBy({
//       left: event.deltaY > 0 ? 200 : -200, 
//       behavior: 'smooth',
//     });
//   };

//   useEffect(() => {
//     const scrollElement = scrollRef.current;
//     if (scrollElement) {
//       scrollElement.addEventListener('wheel', handleScroll);
//     }
//     return () => {
//       if (scrollElement) {
//         scrollElement.removeEventListener('wheel', handleScroll);
//       }
//     };
//   }, []);


//   return (
//     <div>
//         <section>
   
//   <div className="scroll-container ">
//   <button className="scroll-button1" onClick={handleScrollLeft}>
//         <FaArrowLeft /> 
//       </button>

//       <div className="scrollable-content container" ref={scrollRef} style={{paddingTop:'4px'}}>
//         {headings.map((heading, index) => (
//           <a href={heading.urllink} style={{textDecoration:'none'}}>
//           <div 
//             key={index}
//             className={`scroll-item  ${activeTab === heading.head ? 'active' : ''}`}  
//             onClick={() => setActiveTab(heading.head)}
//           >
//             {heading.head}
//           </div></a>
//         ))}
//       </div>

    
//       <button className="scroll-button" onClick={handleScrollRight}>
//         <FaArrowRight /> 
//       </button>
//     </div>

//     </section>

//     </div>
//   );
// };

// export default Subheader;



// ==============================================================================================

import React, { useState, useEffect, useRef } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { FaArrowLeft } from 'react-icons/fa';

const Subheader = ({ headings = [] }) => {
    const [activeTab, setActiveTab] = useState(headings[0]?.head);
    const [isLeftDisabled, setIsLeftDisabled] = useState(true);
    const [isRightDisabled, setIsRightDisabled] = useState(false);
    const scrollRef = useRef(null);

    const handleScrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: 200,
                behavior: 'smooth',
            });
            updateButtonStates();
        }
    };

    const handleScrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: -200,
                behavior: 'smooth',
            });
            updateButtonStates();
        }
    };

    // const handleScroll = (event) => {
    //     event.preventDefault();
    //     scrollRef.current.scrollBy({
    //         left: event.deltaY > 0 ? 200 : -200,
    //         behavior: 'smooth',
    //     });
    //     updateButtonStates();
    // };

    const updateButtonStates = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setIsLeftDisabled(scrollLeft === 0);
            setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
        }
    };

    useEffect(() => {
        const scrollElement = scrollRef.current;
        if (scrollElement) {
            // scrollElement.addEventListener('wheel', handleScroll);
            updateButtonStates();
        }
        return () => {
            if (scrollElement) {
                // scrollElement.removeEventListener('wheel', handleScroll);
            }
        };
    }, []);

    return (
        <div>
            <section>
                <div className="scroll-container">
                    <button 
                        className={`scroll-button1 ${isLeftDisabled ? 'disabled' : ''}`} 
                        onClick={handleScrollLeft} 
                        disabled={isLeftDisabled}
                    >
                        <FaArrowLeft />
                    </button>

                    <div className="scrollable-content container" ref={scrollRef} style={{ paddingTop: '4px' }}>
                        {headings.map((heading, index) => (
                            <a href={heading.urllink} style={{ textDecoration: 'none' }} key={index}>
                                <div 
                                    className={`scroll-item ${activeTab === heading.head ? 'active' : ''}`}  
                                    onClick={() => setActiveTab(heading.head)}
                                >
                                    {heading.head}
                                </div>
                            </a>
                        ))}
                    </div>

                    <button 
                        className={`scroll-button ${isRightDisabled ? 'disabled' : ''}`} 
                        onClick={handleScrollRight} 
                        disabled={isRightDisabled}
                    >
                        <FaArrowRight />
                    </button>
                </div>
            </section>
        </div>
    );
};

export default Subheader;
