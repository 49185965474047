import React, { useEffect, useState } from 'react';

const Typewriter = ({ textArray = [], period = 2000 }) => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(200 - Math.random() * 100);

  const currentTextIndex = loopNum % textArray.length;
  const fullText = textArray[currentTextIndex];

  // Typing effect logic inside useEffect
  useEffect(() => {
    const handleTyping = () => {
      if (isDeleting) {
        setText(fullText.substring(0, text.length - 1));
      } else {
        setText(fullText.substring(0, text.length + 1));
      }

      let newTypingSpeed = 200 - Math.random() * 100;
      if (isDeleting) newTypingSpeed /= 2;

      if (!isDeleting && text === fullText) {
        setIsDeleting(true);
        newTypingSpeed = period; // Pause when the word completes
      } else if (isDeleting && text === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        newTypingSpeed = 500; // Small delay before typing the next word
      }

      setTypingSpeed(newTypingSpeed);
    };

    const typingTimeout = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimeout); // Cleanup timeout on unmount
  }, [text, isDeleting, period, fullText, loopNum, typingSpeed]);

  return (
    <div>
    <h1>
        <div className='typewrite'>
        <span className="wrap">{text}</span>
        </div>
  
    </h1>
    </div>
  );
};

export default Typewriter;
