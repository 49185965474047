import Header from "../Components/Header";
import Footer from "../Components/footer";
const Termscondition = ()=>{
    return(

        <div>
    <section>
        <Header/>
    </section>

    <section classNameName="section" style={{backgroundcolor: 'white', textAlign:"justify", paddingBottom:'30px', marginTop: '5rem'}}>
    <div classNameName=" one container-fluid ps-5 pe-5  " style={{backgroundcolor: 'white',  height: 'fit-content', color: 'black', paddingbottom: '50px',  padding: '50px 5%',
    
    margin: 'auto',
    boxsizing: 'border-box'  }}>

        <p style={{fontfamily: "Inter, sans-serif",   fontsize: '25px', fontWeight:800}}> Welcome to DataMetricks!</p>
        <p className="" style={{ fontfamily: "Inter, sans-serif",  color: '#77838F',fontSize: '16px', fontweight: 400}}>These Terms of Service ("Terms") govern your use of our website, web development, mobile app development, and digital marketing services ("Services"). Please read these Terms carefully before using our Services. By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Services. </p> 
        <p className="" style={{ fontfamily: "Inter, sans-serif",  fontsize: '18px', fontweight: 600}}> <span className="spanbold"> 1. Access and Use </span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•    You must be at least 18 years old or of legal age to enter into a contract to use our Services. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	You are solely responsible for your activity on and use of our Services, including any content you submit.</p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	You may not use our Services for any illegal or unauthorized purpose, including : - </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	Infringing on any intellectual property rights </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	Transmitting harmful content </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	Disrupting our Services or servers </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	Harassing or abusing others </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	Violating any applicable laws or regulations </p> 
        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}> <span className="spanbold"> 2. Content Ownership</span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>•	You retain all ownership rights to the content you submit to us. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	You grant us a non-exclusive, worldwide, royalty-free license to use, modify, reproduce, publish, and distribute your content in connection with providing our Services.</p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}> 	•	We are not responsible for any content you submit to us, and we may remove or disable content that we deem inappropriate or offensive.</p> 

        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}> <span className="spanbold"> 3. Fees and Payment </span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	Our Services are subject to fees specified in our project proposals or agreements. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	You are responsible for all fees associated with your use of our Services. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}> •	We may change our fees at any time without notice.</p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}> •	Payment terms are specified in our project proposals or agreements. </p> 

        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}> <span className="spanbold"> 4. Termination</span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	We may terminate or suspend your access to our Services at any time without notice for any reason, including, but not limited to, breach of these Terms or violation of any applicable laws or regulations. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	You may terminate your use of our Services at any time. </p> 
       
        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}> <span className="spanbold"> 5. Disclaimer of Warranties </span></p> 
        <p className=""style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	OUR SERVICES ARE PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	WE DO NOT WARRANT THAT OUR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ANY DEFECTS WILL BE CORRECTED.</p> 
        <p className=""style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	WE DO NOT WARRANT THAT OUR SERVICES ARE SECURE OR VIRUS-FREE.	</p> 

        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}> <span className="spanbold"> 6. Limitation of Liability </span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	TO THE FULLEST EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OUR SERVICES. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	THIS LIMITATION OF LIABILITY APPLIES REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE. </p> 

        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}><span className="spanbold"> 7. Indemnification </span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>		•	You agree to indemnify and hold us harmless from and against any and all claims, losses, damages, liabilities, costs, and expenses (including attorneys' fees) arising out of or in connection with your use of our Services or your breach of these Terms. </p> 
       
        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}><span className="spanbold">  8. Force Majeure </span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	We shall not be liable for any delay or failure to perform our obligations under these Terms due to any cause beyond our reasonable control, including, but not limited to, acts of God, war, terrorism, strikes, natural disasters, or governmental regulations. </p> 
       
        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}><span className="spanbold">  9. Notice Provision </span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	All notices required or permitted hereunder shall be in writing and shall be deemed to have been duly given when delivered in person, sent by certified or registered mail, return receipt requested, postage prepaid, or sent by overnight courier service, addressed as follows: </p> 
       
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}> <span className="spanbold"> IF TO US: CompanyName :  DataMetricks Consulting Pvt. Ltd , Address: iThum Tower B, Sec-62, Noida , Email: contact@datametricks.com .</span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}><span className="spanbold">  IF TO YOU: [Client Name] [Client Address] [Client Email Address]. </span></p>

        <p className="" style={{ paddingtop: '10px', fontfamily: "Inter, sans-serif",    fontsize: '18px', fontWeight:600}}><span className="spanbold"> 10. Entire Agreement</span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>		•	These Terms constitute the entire agreement between you and us regarding your use of our Services.</p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>		•	Any prior or contemporaneous communications or agreements, whether oral or written, are superseded by these Terms. </p> 

        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}><span className="spanbold"> 11. Amendments </span> </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>		•	We may amend these Terms at any time without notice. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>		•	The amended Terms will be effective immediately upon posting on our website. </p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>		•	Your continued use of our Services after the amended Terms are posted constitutes your acceptance of the amended Terms.</p> 

        <p className="" style={{ fontfamily: "Inter, sans-serif",    fontsize: '18px', fontweight: 600}}><span className="spanbold"> Contact Us </span></p> 
        <p className="" style={{ paddingleft: '50px',   fontfamily: "Inter, sans-serif",  color: '#77838F',fontsize: '15px', fontweight: 400}}>	•	If you have any questions about these Terms, please contact us at <span className="spanbold">Email: contact@datametricks.com</span> or <span className="spanbold"> Ph.No: 0120 4574492 </span>. </p> 
        
    </div>
</section>


    <section>
        <Footer/>
    </section>
</div>

    )
}
export default Termscondition;