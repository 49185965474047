import Header from "../Components/Header";
import Footer from "../Components/footer";
const Privacypolicy = ()=>{
    return(
<div>
    <section>
        <Header/>
    </section>
    <section className="section" style={{ textAlign: 'justify', paddingBottom: '30px', marginTop: '5rem'}}>
        <div className=" one container ps-5 pe-5  " style={{backgroundColor: 'white', height: 'fit-content', color: 'black', paddingBottom: '50px', padding: '50px 5%', margin: 'auto', boxSizing: 'border-box'}}>
          <h2 className="termConditionSectionH">Privacy Policy for DataMetricks Consulting Pvt. Ltd. </h2>
          <p className="termConditionSectionP" style={{color: '#77838F'}}><span style={{color: 'rgb(7, 7, 7)'}}>Last Updated:</span>  10 May 2024 </p>
          <p className="termConditionSectionP">This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information. We use your personal data to provide and improve the service. By using the service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
          <h2 className="termConditionSectionH">Interpretation and Definitions</h2>
          <h2 className="termConditionSectionH">Interpretation</h2>
          <p className="termConditionSectionP">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h2 className="termConditionSectionH">Definitions</h2>
          <p className="termConditionSectionP">For the purposes of this Privacy Policy: </p>
          <p className="termConditionSectionP"><span className="spanbold">• Account</span> means a unique account created for you to access our service or parts of our service. </p>
          <p className="termConditionSectionP"><span className="spanbold">• Affiliate</span>  means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority. service. </p>
          <p className="termConditionSectionP"><span className="spanbold">• Company </span> (referred to as either <span className="spanbold">"the Company", "We", "Us" or "Our")</span> refers to <span className="spanbold"> DataMetricks Consulting Pvt. Ltd., 1224 iThum Building, Tower B, Sector 62, Noida, 201309.</span></p>
          <p className="termConditionSectionP"><span className="spanbold"> • Cookies</span>  are small files that are placed on your computer, mobile device or any other device by a website, containing the details of your browsing history on that website among its many uses.</p>
          <p className="termConditionSectionP"><span className="spanbold"> • Country</span>  refers to: India</p>
          <p className="termConditionSectionP"><span className="spanbold"> • Device</span>  means any device that can access the service such as a computer, a mobile or a digital tablet. </p>
          <p className="termConditionSectionP"><span className="spanbold"> • Personal Data</span>   is any information that relates to an identified or identifiable individual.</p>
          <p className="termConditionSectionP"><span className="spanbold"> • Personal Data</span>   is any information that relates to an identified or identifiable individual.</p>
          <p className="termConditionSectionP"><span className="spanbold"> • Service</span>  refers to the Website.</p>
          <p className="termConditionSectionP"><span className="spanbold"> • Service Provider</span> means any natural or legal person who processes the data on behalf of the company. It refers to third-party companies or individuals employed by the company to facilitate the service, to provide the service on behalf of the company, to perform services related to the service or to assist the company in analyzing how the service is used. </p>
          <p className="termConditionSectionP"><span className="spanbold">  • Usage Data</span>  refers to data collected automatically, either generated by the use of the Service or from the service infrastructure itself (for example, the duration of a page visit).</p>
          <p className="termConditionSectionP"><span className="spanbold"> • Website</span>    refers to<span className="spanbold"> DataMetricks Consulting Pvt. Ltd.,</span> accessible from <span className="spanbold"> https://datametricks.com/ </span></p>
          <p className="termConditionSectionP"><span className="spanbold"> • You</span>   mean the individual accessing or using the service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
          <p className="termConditionSectionP"><span className="spanbold"> Collecting and Using Your Personal Data:</span></p>
          <p className="termConditionSectionP"><span className="spanbold"> Types of Data Collected</span></p>
          <p className="termConditionSectionP"><span className="spanbold"> Personal Data</span></p>
          <p className="termConditionSectionP">  While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
          <p className="termConditionSectionP" style={{fontFamily: '"Inter", sans-serif', color: '#77838F', fontSize: '15px', fontWeight: 'bold'}}> • Email address</p>
          <p className="termConditionSectionP" style={{fontFamily: '"Inter", sans-serif', color: '#77838F', fontSize: '15px', fontWeight: 'bold'}}> • First name and last name</p>
          <p className="termConditionSectionP" style={{fontFamily: '"Inter", sans-serif', color: '#77838F', fontSize: '15px', fontWeight: 'bold'}}>  • Usage Data</p>
          <p className="termConditionSectionP" style={{fontFamily: '"Inter", sans-serif', color: '#77838F', fontSize: '15px', fontWeight: 'bold'}}> <span className="spanbold"> Usage Data</span></p>
          <p className="termConditionSectionP">  While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
          <p className="termConditionSectionP">  When you access the service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, Your mobile operating system, the type of mobile internet browser you use, unique device identifiers and other diagnostic data.</p>
          <p className="termConditionSectionP">  We may also collect information that your browser sends whenever you visit our service or when you access the service by or through a mobile device.</p>
          <p className="termConditionSectionP" style={{fontFamily: '"Inter", sans-serif', color: '#77838F', fontSize: '15px', fontWeight: 'bold'}}> <span className="spanbold"> Tracking Technologies and Cookies</span></p>
          <p className="termConditionSectionP"> We use cookies and similar tracking technologies to track the activity of our service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our service. The technologies we use may include:</p>
          <p className="termConditionSectionP"><span className="spanbold">• Cookies or browser cookies:</span> A cookie is a small file placed on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, You may not be able to use some parts of our service. </p>
          <p className="termConditionSectionP"> <span className="spanbold"> • Web Beacons:</span> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity). </p>
          <p className="termConditionSectionP">Cookies can be "Persistent" or "Session" Cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser. </p>
          <p className="termConditionSectionP">We use both Session and Persistent Cookies for the purposes set out below: </p>
          <p className="termConditionSectionP"> <span className="spanbold"> • Necessary / Essential Cookies </span></p>
          <p className="termConditionSectionP"> <span className="spanbold">  Type: </span> Session Cookies</p>
          <p className="termConditionSectionP"> <span className="spanbold">  Administered by: </span> Us</p>
          <p className="termConditionSectionP"> <span className="spanbold">  Purpose: </span> These cookies are essential to provide you with services available through the website and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.</p>
          <p className="termConditionSectionP"> <span className="spanbold"> • Cookies Policy / Notice Acceptance Cookies </span></p>
          <p className="termConditionSectionP"> <span className="spanbold">  Type: </span> Persistent Cookies</p>
          <p className="termConditionSectionP"> <span className="spanbold">  Administered by: </span> Us</p>
          <p className="termConditionSectionP"> <span className="spanbold">  Purpose: </span> Purpose: These cookies allow us to remember choices you make when you use the website, such as remembering your login details or language preference. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Website.</p>
          <p className="termConditionSectionP"> <span className="spanbold"> Use of Your Personal Data </span></p>
          <p className="termConditionSectionP"> The Company may use Personal Data for the following purposes:</p>
          <p className="termConditionSectionP"><span className="spanbold">•</span>  To provide and maintain our service, including to monitor the usage of our service. </p>
          <p className="termConditionSectionP"><span className="spanbold">• To manage Your Account:</span>  to manage your registration as a user of the service. The Personal Data you provide can give you access to different functionalities of the service that are available to you as a registered user.</p>
          <p className="termConditionSectionP"><span className="spanbold">• For the performance of a contract:</span>  the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with us through the service.</p>
          <p className="termConditionSectionP"><span className="spanbold">• To contact you:</span>  To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
          <p className="termConditionSectionP"><span className="spanbold"> • </span> To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
          <p className="termConditionSectionP"><span className="spanbold">• To manage your requests:</span>   To attend and manage your requests to us.</p>
          <p className="termConditionSectionP"><span className="spanbold">• For business transfers:</span>  We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by us about our service users is among the assets transferred.</p>
          <p className="termConditionSectionP"><span className="spanbold"> • For other purposes:</span>  We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our service, products, services, marketing, and your experience. </p>
          <p className="termConditionSectionP">We may share your personal information in the following situations:</p>
          <p className="termConditionSectionP"><span className="spanbold">• With service providers:</span>  We may share your personal information with service providers to monitor and analyze the use of our Service, to contact You. </p>
          <p className="termConditionSectionP"><span className="spanbold">• For business transfers:</span> We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. </p>
          <p className="termConditionSectionP"><span className="spanbold">• With affiliates:</span> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</p>
          <p className="termConditionSectionP"><span className="spanbold">• With business partners:</span>  We may share your information with our business partners to offer you certain products, services or promotions. </p>
          <p className="termConditionSectionP"><span className="spanbold">• With other users:</span> When you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. </p>
          <p className="termConditionSectionP"><span className="spanbold">• With Your consent:</span>  We may disclose your personal information for any other purpose with your consent.</p>
          <p className="termConditionSectionP"><span className="spanbold">Retention of Your Personal Data</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to: </p>
          <p className="termConditionSectionP"><span className="spanbold" /> The company will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or We are legally obligated to retain this data for longer time periods.</p>
          <p className="termConditionSectionP"><span className="spanbold">Transfer of Your Personal Data</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> Your information, including personal data, is processed at the company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. </p>
          <p className="termConditionSectionP"><span className="spanbold" />Your consent to this privacy policy followed by your submission of such information represents your agreement to that transfer. </p>
          <p className="termConditionSectionP"><span className="spanbold" /> The company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy and no transfer of your personal data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
          <p className="termConditionSectionP"><span className="spanbold">Disclosure of Your Personal Data</span></p>
          <p className="termConditionSectionP"><span className="spanbold">Business Transactions</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> If the company is involved in a merger, acquisition or asset sale, Your personal data may be transferred. We will provide notice before your personal data is transferred and becomes subject to a different privacy policy. </p>
          <p className="termConditionSectionP"><span className="spanbold">Law Enforcement</span> </p>
          <p className="termConditionSectionP"><span className="spanbold" /> Under certain circumstances, the company may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). </p>
          <p className="termConditionSectionP"><span className="spanbold">Other Legal Requirements</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> The company may disclose your personal data in the good faith belief that such action is necessary to: </p>
          <p className="termConditionSectionP"><span style={{fontWeight: 600}}> • Comply with a legal obligation</span></p>
          <p className="termConditionSectionP"><span style={{fontWeight: 600}}> • Protect and defend the rights or property of the company</span></p>
          <p className="termConditionSectionP"><span style={{fontWeight: 600}}> • Prevent or investigate possible wrongdoing in connection with the Service</span></p>
          <p className="termConditionSectionP"><span style={{fontWeight: 600}}> • Protect the personal safety of users of the Service or the public</span></p>
          <p className="termConditionSectionP"><span style={{fontWeight: 600}}>  • Protect against legal liability</span></p>
          <p className="termConditionSectionP"><span className="spanbold">Security of Your Personal Data</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> The security of your personal data is important to us, but remember that no method of transmission over the internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect your personal data, We cannot guarantee its absolute security. </p>
          <p className="termConditionSectionP"><span className="spanbold">Children's Privacy</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and You are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers. </p>
          <p className="termConditionSectionP"><span className="spanbold" /> If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information. </p>
          <p className="termConditionSectionP"><span className="spanbold">Links to Other Websites</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> Our service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit. </p>
          <p className="termConditionSectionP"><span className="spanbold" /> We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services. </p>
          <p className="termConditionSectionP"><span className="spanbold">Your rights: </span></p>
          <p className="termConditionSectionP"><span className="spanbold">Our responsibility for your rights</span></p>
          <p className="termConditionSectionP"><span className="spanbold" /> DataMetricks Consulting Pvt. Ltd. is responsible as the data controller for ensuring that your personal data is processed in accordance with the law and that the rights you have as a user are enforced. You can contact us at any time if you want to exercise your rights. You will find our contact details at the bottom of this privacy notice. </p>
          <p className="termConditionSectionP"><span className="spanbold" /> We are obliged to respond to your request for the exercise of your rights within one month of hearing from you. If your request is complicated or if a large number has been received, we have the right to extend the time by another two months. If we cannot do what you want us to do within one month, we will inform you of the reason for the delay and of your right to complain to the regulatory authority and to seek legal remedies.</p>
          <p className="termConditionSectionP"><span className="spanbold" />  All information, communication, and all measures that we carry out are free of charge for you. If, on the other hand, what you request about your rights is clearly unfounded or unreasonable, we have the right to charge an administrative fee to provide you with thes information or carry out the requested action, or to refuse to comply with your request.</p>
          <p className="termConditionSectionP"><span className="spanbold">Your right of access, rectification, erasure and restriction:</span></p>
          <p className="termConditionSectionP"><span className="spanbold">You have the right to request from DataMetricks Consulting Pvt. Ltd.</span></p>
          <p className="termConditionSectionP"><span className="spanbold">•</span> Access to your personal data. This means that you have the right to request access to the personal data that we process about you. You also have the right to receive information free of charge about which personal data we process about you. For any additional copies, we have the right to charge an administration fee. If you make a request in electronic format, for example by email, we will provide you with the information in a commonly used electronic format. </p>
          <p className="termConditionSectionP"><span className="spanbold">•</span>  Correction of your personal data. We will, at your request or on our own initiative, correct, de-identify, delete or supplement information that we discover is incorrect, incomplete or misleading. You also have the right to supplement with additional personal data if something relevant is missing. </p>
          <p className="termConditionSectionP"><span className="spanbold">• Deletion of personal data.</span></p>
          <p className="termConditionSectionP"><span className="spanbold">(1)</span>  Processing is necessary for freedom of expression and information </p>
          <p className="termConditionSectionP"><span className="spanbold"> (2)</span> Processing is necessary for us to comply with a legal obligation </p>
          <p className="termConditionSectionP"><span className="spanbold">(3)</span>  Treatment is necessary for purposes of public interest in the field of public health </p>
          <p className="termConditionSectionP"><span className="spanbold">(4)</span>  Processing is necessary for archival purposes of general interest, scientific or historical research purposes or for statistical purposes, or </p>
          <p className="termConditionSectionP"><span className="spanbold">(5)</span>  Processing is necessary to establish, exercise or defend against legal claims.</p>
          <p className="termConditionSectionP"><span className="spanbold">(6)</span>  Deletion of your personal data must take place if none of the circumstances above exist and if:</p>
          <p className="termConditionSectionP"><span className="spanbold" /> (i) The personal data are no longer needed for the purpose for which we collected them, </p>
          <p className="termConditionSectionP"><span className="spanbold" /> (ii) We process your personal data based on your consent and you revoke this, </p>
          <p className="termConditionSectionP"><span className="spanbold" /> (iii) You object to the processing of your personal data which takes place after a balancing of interests and we do not have compelling legitimate interests that outweigh your interests or rights and freedoms, </p>
          <p className="termConditionSectionP"><span className="spanbold" /> (iv) We have used the personal data in an unauthorized manner, or </p>
          <p className="termConditionSectionP"><span className="spanbold" /> (v) We have a legal obligation to delete personal data. </p>
          <p className="termConditionSectionP"><span className="spanbold">(7) Limitation of treatment.</span>  This means that we temporarily limit the processing of your personal data. You have the right to request restriction when: </p>
          <p className="termConditionSectionP"><span className="spanbold" /> (a) You consider your personal data to be incorrect and have requested correction according, while we are investigating the correctness of the personal data,</p>
          <p className="termConditionSectionP"><span className="spanbold" /> (b) The use is illegal, and you do not want the data to be deleted, </p>
          <p className="termConditionSectionP"><span className="spanbold" /> (c) We as the data controller no longer need to process the personal data for our purposes of the processing, but you need it to be able to establish, assert or defend a legal claim, or </p>
          <p className="termConditionSectionP"><span className="spanbold" /> (d) You have objected to processing, pending our assessment of whether our legitimate interests outweigh your legitimate interests. </p>
          <p className="termConditionSectionP"><span className="spanbold" /> We will take reasonable steps to notify anyone who has received personal data that we have corrected, deleted, or restricted access to your personal data after you have requested us to do so. At your request, we will also provide additional information about the recipients of your personal data. </p>
          <p className="termConditionSectionP"><span className="spanbold">Your right to object to processing</span> </p>
          <p className="termConditionSectionP"><span className="spanbold" /> You have the right to object to the processing of your personal data based on a legitimate or public interest. If you object to such processing, we will only continue to use your personal data if we have compelling legitimate interests in the processing that outweigh your interests or rights and freedoms or if continued processing is necessary to establish, assert or defend a legal claim. </p>
          <p className="termConditionSectionP"><span className="spanbold" />If you do not want us to process your personal data for direct marketing, you have the right to object to such processing at any time by contacting us. Once we have received your objection, we will stop processing your personal data for this purpose.</p>
          <p className="termConditionSectionP"><span className="spanbold">Your right to withdraw consent</span></p>
          <p className="termConditionSectionP"><span className="spanbold" />For processing where we use your consent as a legal basis, you have the right to withdraw your consent at any time by contacting us. You will find our contact details at the bottom of this Privacy Notice. </p>
          <p className="termConditionSectionP"><span className="spanbold">Your right to data portability </span> </p>
          <p className="termConditionSectionP"><span className="spanbold" /> You have the right to data portability when the processing of your personal data is automated and the legal basis for processing is your consent or performance of an agreement. The right to data portability means that you have the right to obtain your personal data in a structured, commonly used, and machine-readable format and to transfer this data to another data controller. You can also request that we transfer the personal data directly to another personal data controller. </p>
          <p className="termConditionSectionP"><span className="spanbold">Changes to this Privacy Policy </span> </p>
          <p className="termConditionSectionP"><span className="spanbold" />We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>
          <p className="termConditionSectionP"><span className="spanbold" />We will let you know via email and/or a prominent notice on our service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. </p>
          <p className="termConditionSectionP"><span className="spanbold" />You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </p>
          <p className="termConditionSectionP"><span className="spanbold">Contact Us</span> </p>
          <p className="termConditionSectionP"><span className="spanbold" /> If you have any questions about this Privacy Policy, You can contact us:</p>
          <p className="termConditionSectionP"><span className="spanbold">Address:</span>  1224 iThum Building, Tower B, Sector 62, Noida, 201309</p>
          <p className="termConditionSectionP">   <span className="spanbold">Email: </span>contact@datametricks.com </p>
        </div>
      </section>
    <section>
        <Footer/>
    </section>
</div>

    )
}
export default Privacypolicy;