

const SocialmediaCard = ()=>{

const socialimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/social-img-1.png";
const socialimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/social-img-2.png";
const socialimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/social-img-3.png";
const socialimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/social-img-4.png";
const socialimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/social-img-5.png";

    return(

        <section>
                <div>

                     {/* 1st Card  */}

                        <section>
                  
                  <div className=" mt-lg-5 ">
                    <div className="container  ">
                        <div className="row card123 mx-lg-5  pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Social Media Strategy Development</h1>

                            <p className='cutting-subtitle mx-lg-5 my-4 font-18'>We begin with a comprehensive social media strategy tailored to your business goals. Our experts analyze your target audience, competitors, and market trends to develop a robust plan that maximizes your brand’s online presence. We focus on creating engaging content, identifying key platforms, and setting measurable objectives to drive growth and achieve your marketing goals.  </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={socialimg1} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 2nd Card  */}

             <section>
                
                <div>
                  <div className="container mt-lg-3">
                      <div className="row card123 mx-lg-5  pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6 col-sm-6 col-12 textcenter  order-2 order-lg-1">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Content Creation & Management</h1>
                          <p className='cutting-subtitle mx-lg-5 my-4 font-18 '>Creating compelling content is at the heart of our social media services. Our creative team produces high-quality visuals, videos, and copy that resonate with your audience and reflect your brand’s voice. We manage your social media accounts to ensure a consistent posting schedule, engaging with your followers, and keeping your content fresh and relevant. Our goal is to build a strong, engaging online presence for your brand. </p>
                          </div>
                          <div className="col-lg-6  col-sm-6 col-12 order-1 order-lg-2">
                          <img src={socialimg2} alt="" className="img-fluid "  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>

            {/* 3rd Card  */}

           <section>
                  
                  <div>
                    <div className="container mt-3 ">
                        <div className="row card123 mx-lg-5  pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6  col-sm-6 col-12 textcenter order-lg-1 order-2">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Community Management</h1>

                            <p className='cutting-subtitle mx-lg-5 my-4 font-18'>Building and nurturing a community around your brand is essential for long-term success. Our community management services involve actively engaging with your followers, responding to comments and messages, and fostering a positive and interactive environment. We aim to create loyal brand advocates who will spread the word about your products and services, enhancing your online reputation and customer relationships.  </p>
                            </div>
                            <div className="col-lg-6  col-sm-16 col-12 order-lg-2 order-1">
                            <img src={socialimg3} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 4th Card  */}

             <section>
                
                <div >
                  <div className="container mt-lg-3 ">
                      <div className="row card123 mx-lg-5  pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6  col-12 textcenter order-lg-1 order-2">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Social Media Advertising</h1>
                          <p className='cutting-subtitle mx-lg-5 my-4 font-18'>Maximize your reach and drive targeted traffic with our social media advertising services. We design and implement paid ad campaigns on platforms like Facebook, Instagram, LinkedIn, and Twitter, tailored to your business objectives. Our team focuses on precise audience targeting, compelling ad creatives, and continuous optimization to ensure high ROI and effective budget utilization. Whether it’s brand awareness, lead generation, or sales, we deliver results.  </p>
                          </div>
                          <div className="col-lg-6  col-sm-6 col-12 order-lg-2 order-1">
                          <img src={socialimg4} alt="" className="img-fluid "  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>


                {/* 5th Card  */}

                <section>
                  
                  <div >
                    <div className="container  mt-3">
                        <div className="row card123 mx-lg-5  pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6  col-sm-6 col-12 textcenter orderlg-1 order-2">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Social Media Analytics & Reporting</h1>

                            <p className='cutting-subtitle mx-lg-5 my-4 font-18'>Data-driven insights are crucial for refining your social media strategy. We provide comprehensive analytics and reporting to track the performance of your social media campaigns. Our detailed reports cover key metrics such as engagement rates, follower growth, reach, and conversion rates. By analyzing this data, we identify what’s working and what needs improvement, ensuring your social media efforts are always aligned with your business goals. </p>
                            </div>
                            <div className="col-lg-6  col-sm-6 col-12 order-lg-2 order-1">
                            <img src={socialimg5} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                </div>

        </section>
    );
}
export default SocialmediaCard;