import Footer from "../Components/footer";
import Header from "../Components/Header";
import Form from "../Components/Form";
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";
import Subheader from "../Components/Subheader";
import React, { useState, useEffect } from 'react';




const GardeningProject = () => {

     // ================= Subheader hide and show start ========================

  const [isScrollingDown, setIsScrollingDown] = useState(false);
  let lastScrollTop = 0;

useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      // Scrolling down, hide the header
      setIsScrollingDown(true);
    } else {
      // Scrolling up, show the header
      setIsScrollingDown(false);
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  // ================= Subheader hide and show End ========================


const headings = [

    {
        head:'Gardening Project',urllink:'/our-completed-projects/gardening'
        },
  {
    head:'Web Development',urllink:'/service/website-design-development-solutions'
    },
  {
    head:'Custom Website',urllink:'/service/custom-website-design-development-solutions'
    },
  {
    head:'Graphic Designing',urllink:'/services/graphic-desinging'
    },
  {
    head:'Digital Marketing',urllink:'/services/digital-marketing'
    },
  {
      head:'SEO',urllink:'/service/search-engine-optimization-seo'
 },
  {
      head:'Social Media Marketing',urllink:'/service/social-media-marketing'
 },
 {
  head:'Performance Marketing',urllink:'/services/performance-marketing'
},
{
head:'Mobile App Development',urllink:'/service/mobile-app-design-development-solutions'
},
{
head:'E Commerce Website',urllink:'/service/ecommerce-website-design-development-solutions'
},
{
head:'CMS Website',urllink:'/service/cms-website-design-development-solutions'
},

];

const rajesh = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/rajeshimg.svg";
const imtiyaj = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/imtiyajimg.svg";
const ravi = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/raviimg.svg";
const harsh = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/harshimg.svg";
const gardeningbg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/gardeningbg1.svg";
const gardeningimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/gardeningimg1.svg";
const gardeningimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/gardeningimg2.svg";
// const casestudyimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/casestudy-img3.svg";
const casestudyimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/casestudy-img4.svg";
// const casestudyimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/codetechimg4.svg";
const projectimg4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/project-img4.svg';


const gardningData=[
    { 
        heading:"Codetech",
        title:"We designed an intuitive and engaging UI for Codetech, a website development company. The design focuses on enhancing user experience and showcasing their services effectively.",
        bgcolor:"rgba(33, 37, 41, 1)",
        img:projectimg4,
         url:"/our-completed-projects/codetech"

    },
];

    return (

        <div>
             <SEOPage 
                title="3D Gards: Gardening Services Website Design & Development"
                description="We developed the 3D Gards website, a vibrant platform showcasing gardening services with detailed descriptions and a user-friendly contact form."
                canonicalUrl="https://www.datametricks.com/our-completed-projects/gardening"
            />
            
            <div>
                <Header/>
            </div>

            <section className='d-none d-lg-block d-md-block d-sm-none pt-1  '>
      <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Subheader  headings={headings}/>
        </div>
      </section>

         <section className="mt90" style={{marginTop:'160px'}}>
                <div className="container casestudycontainer position-relative">
                 <div className="image-wrapper">
                       <img src={gardeningbg1} alt="" className="img-fluid w-100 casestudyimg-fluid" />
                      <div className="casestudytext-overlay text-center " >
                            <p  className="casestudyheadline mb-0">Creative Design and Development </p>
                            <p  className="casestudyheadline">for Gardening Services Website </p>
                     </div>
                </div>
              </div>
        </section>

        <section>
            <div className="container text-center mt-4">
                <img src={gardeningimg1} alt="" className="img-fluid" />
            </div>
        </section>

        <section>
            <div className="container mt-lg-5 mt-30">
                <div className="row justify-content-center">
                    <div className="col-lg-5 textcenter">
                        <p className="fw-600 fs-24 plus font-24" style={{color:'rgba(64, 64, 64, 1)'}}>Project Summary</p>
                        <p className="fs-16 font-16 plus" style={{color:'rgba(82, 86, 101, 1)'}}>Our client, a provider of comprehensive gardening services, required a vibrant and user-friendly website to better connect with potential customers and showcase their expertise. The objective was to create an engaging online presence that effectively highlights their range of services and makes it easy for customers to get in touch.</p>

                     
                      <div className="d-flex flex-wrap gap-3 justify">
                        <div>
                            <button type="button" class="btn  btn-sm font-16  fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Figma</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16  fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Developer</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm  font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Html & Css</button>
                        </div>
                     </div>
                     <div className="d-flex gap-3 pt-3 justify">
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Javascript</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Bootstrap</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Php</button>
                        </div>
                        {/* <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Figma</button>
                        </div> */}
                      
                        
                     </div>
                        
                    </div>
                    <div className="col-lg-5 ps-lg-5 pl0 pl-0 pt-10">
                        <p className="fw-600 fs-24 plus textcenter pt-20 font-24" style={{color:'rgba(64, 64, 64, 1)'}}>Project Details</p>
                        <div className="p-4  " style={{backgroundColor:'rgba(251, 251, 251, 1)',borderRadius:'16px'}}>
                        <p className="fw-600 fs-16 plus mb-0 font-16" style={{color:'rgba(39, 39, 39, 1)'}}>Project Type : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>Gardening Services Website</span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Duration : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>14 Days</span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>
                            Website:  <a href="https://www.3dgard.se" className="fw-700 fs-15 plus font-16" style={{color:'rgba(115, 144, 249, 1)'}}>Click Here</a></p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Tools : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>Html & Css, Javascript, Bootstrap, Php </span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Team : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>Imtiyaj, Rajesh, Ravi, Harsh</span> </p>

                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="container mt-lg-5 mt-10">
        <div className="p-4 " style={{border:'0.90px solid rgba(211, 211, 211, 1)',borderRadius:'16px'}} >

            <div className="row text-center justify-content-center"> 
            <div className="d-block d-sm-block d-lg-none d-md-none">
                    <p className="fw-600 fs-24 font-23 plus  " style={{color:'rgba(64, 64, 64, 1)'}}>Team Member</p>
                </div>
           
            <div className="col-lg-2 col-md-6 col-6 d-none d-sm-none d-lg-block d-md-block">
                <div>
                    <p className="fw-600 fs-24 font-24 plus pt-5 " style={{color:'rgba(64, 64, 64, 1)'}}>Team Member</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={imtiyaj} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 plus font-16 mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Imtiyaj</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Developer</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={rajesh} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 font-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Rajesh Pandey</p>
                        <p className="fs-14 plus font-16" style={{color:'rgba(151, 151, 151, 1)'}}>Developer</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={ravi} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 font-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Ravi Chaudhary</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>DevOps</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={harsh} alt="" className="img-fluid" />
                        <p className="fw-600 font-16 fs-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Harsh Rohilla</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Designer</p>
                </div>
         
            </div>
            
            {/* <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={casestudyimg3} alt="" className="img-fluid" />
                        <p className="fw-600 font-16 fs-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Sylvester Stallone</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Designer</p>
                </div>
         
            </div> */}
           </div>
            </div>
           
        </section>


        <section >
            <div className="container mt-5 ">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <img src={casestudyimg4} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 textcenter mt-10">
                        <p className="fw-700 font-24 fs-30 plus pt-20">Developed Wireframes for Every Screens</p>
                        <p className="fs-18 font-16 plus" style={{color:'rgba(46, 46, 46, 0.6)'}}>We began with detailed wireframes to map out the user journey, ensuring a logical flow of information and ease of navigation. The design phase focused on creating a visually appealing and vibrant interface that reflects the natural beauty of gardening. We incorporated high-quality images of gardens and plants, along with clear, concise descriptions of the services offered. The color scheme was chosen to evoke a sense of freshness and vitality, aligning with the client's brand identity.</p>
                    </div>
                </div>

            </div >   
        </section>

        <section>
            <div className=" mt-lg-5 mt-20">
                <img src={gardeningimg2} alt="" className="img-fluid" />
            </div>
        </section>

        <section className="mt-4 text-center">
 <p className="fw-600 fs-40 plus newfont-24  ">Our Next Project</p>

<div className="container projectcontainer mt-4">
  <div className="row justify-content-center">
  {gardningData?.map((item1, index) => (
    <div className="col-lg-6 mb-5" eventKey={index}>
    <a href={item1.url} style={{textDecoration:'none'}}><div className="projectresponsive-box py-4 px-4 " style={{backgroundColor:item1.bgcolor}}>
      <div className="projectimage-container">
          <img src={item1.img} alt="" className="projectresponsive-img img-fluid hover1" />
        </div>
        <h1 className="projecttitle">{item1.heading}</h1>
        <p className="projectdescription">
         {item1.title}
        </p>
       
      </div></a>
    </div>
    )  )}
  </div>
</div> 
 </section>

     

<section className=''>
        <div className='newhome-bg1 resnewhome-bg1'>  
  <div className='container p-30'>
    <div className='row justify-content-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <p className='fs-50 fw-600 plus mb-0 text-white font-25 mt-35' style={{marginTop:'200px'}}>Contact for your </p>
          <p className='fs-50 fw-700 plus mt0 font-25 mt-35' style={{color:'rgba(255, 162, 49, 1)'}}> New Website</p>

      </div>
      <div className='col-lg-4 col-md-6 col-12'>
         <div>
            <Form/>
         </div>
        </div>
    </div>
  </div>
  </div>
  
</section>
       

<section>
  <div>
    <Chatbox/>
  </div>
</section>

            <div className="">
                <Footer/>
            </div>


        </div>

    );
};
export default GardeningProject;