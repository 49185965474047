
import React, { useEffect, useRef } from 'react';

const sliderlogo1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo1.svg";
const sliderlogo2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo2.svg";
const sliderlogo3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo3.svg";
const sliderlogo4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo4.svg";
const sliderlogo5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo5.svg";
const sliderlogo6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo6.svg";
const sliderlogo7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sliderlogo7.svg";


const Logoslider = () => {

    const marqueeRef = useRef(null);

    useEffect(() => {
      // Safely clone the cards for an infinite loop
      const marqueeContainer = marqueeRef.current;
  
      if (marqueeContainer && marqueeContainer.children.length) {
        // Clone only if there are items present in the list
        const clone = marqueeContainer.innerHTML;
        marqueeContainer.innerHTML += clone;
      }
    }, []);

  return (


    <div>

        <section className='p-4' style={{backgroundColor:'black'}}>

        <div className="marqueslide">
      <ul className="testimonial-cards marquestarthere" ref={marqueeRef}>
        <li className="marqueme">
          <img src={sliderlogo1} alt="" />
        </li>
        <li className="marqueme">
        <img src={sliderlogo2} alt="" className='sliderimage' />
        </li>
        <li className="marqueme">
        <img src={sliderlogo3} alt="" className='sliderimage'/>
        </li>
        <li className="marqueme">
        <img src={sliderlogo4} alt="" className='sliderimage'/>
        </li>
        <li className="marqueme">
        <img src={sliderlogo5} alt="" className='sliderimage'/>
        </li>
        <li className="marqueme">
        <img src={sliderlogo6} alt="" className='sliderimage'/>
        </li> 
        <li className="marqueme">
        <img src={sliderlogo7} alt="" className='sliderimage'/>
        </li>
      </ul>
    </div>

        </section>

        

     </div>



  )
};
export default Logoslider;
