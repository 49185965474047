import React, { useState, useEffect } from 'react';
import Header from "../Components/Header";
import Minicontact from "../Components/Minicontact";
import Footer from "../Components/footer";
import Modal from 'react-bootstrap/Modal';
import Bigcontact from '../Components/Bigcontact';
import Testimonial1 from "../Components/Testimonial1";
// import Blog from "../Components/Blog";
import Faq from "../Components/Faq";
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";
import Blog1 from "../Components/Blog1";
import { Helmet } from "react-helmet";
import Subheader from '../Components/Subheader';

const Seo = () => {

      // ================= Subheader hide and show start ========================

  const [isScrollingDown, setIsScrollingDown] = useState(false);
  let lastScrollTop = 0;

useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      // Scrolling down, hide the header
      setIsScrollingDown(true);
    } else {
      // Scrolling up, show the header
      setIsScrollingDown(false);
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  // ================= Subheader hide and show End ========================

    const headings = [

        {
            head:'SEO',urllink:'/service/search-engine-optimization-seo'
       },
        {
            head:'Social Media Marketing',urllink:'/service/social-media-marketing'
       },
       {
        head:'Performance Marketing',urllink:'/services/performance-marketing'
   },
   {
    head:'Graphic Designing',urllink:'/services/graphic-desinging'
},
{
    head:'Web Development',urllink:'/service/website-design-development-solutions'
},
{
    head:'Mobile App Development',urllink:'/service/mobile-app-design-development-solutions'
},
{
    head:'E Commerce Website',urllink:'/service/ecommerce-website-design-development-solutions'
},
{
    head:'Custom Website',urllink:'/service/custom-website-design-development-solutions'
},
{
    head:'CMS Website',urllink:'/service/cms-website-design-development-solutions'
},
{
    head:'Digital Marketing',urllink:'/services/digital-marketing'
},
        
       
      ];

    const [categories, setCategories] = useState([]);

    useEffect(() => {
      fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
        .then((response) => response.json())
        .then((data) => setCategories(data))
        .catch((error) => console.error('Error fetching categories:', error));
    }, []);
  
   
    const categoryId=4;

    const serviceSchemaData7 = 
    [
    {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Search Engine Optimization (SEO)",
        "provider": {
          "@type": "Organization",
          "name": "Datametricks Consulting Pvt Ltd",
          "url": "https://www.datametricks.com/",
          "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 7303946657",
            "contactType": "Customer Service",
            "email": "contact@datametricks.com"
          }
        },
        "description": "Datametricks offers comprehensive SEO services to help businesses improve their search engine rankings, boost organic traffic, and increase online visibility.",
        "areaServed": {
          "@type": "Place",
          "name": "India"
        }
      }
    ];
    

const Seobg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Seo-bg1.png";
const Seobg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Seo-bg2.png";
const Seobg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Seo-bg-4.png";
const Seobg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Seo-bg-5.png";
const Seobg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Seo-bg-6.png";
const Seobg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Seo-bg-7.png";
const SEOimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/SEOimg1.png";
const SEOimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/SEOimg2.png";
const SEOimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/SEOimg3.png";
const SEOimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/SEOimg4.png";
const SEOimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/SEOimg5.png";
const Seonewimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg1.png";
const Seo_newimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seo-newimg2.png";
const Seo_newimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seo-newimg3.png";
// const testimonialimage = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/testimonial-img.png";
const Seobg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/SEO.png";


   const [activeComponent, setActiveComponent] = useState('onPageSEO');
   const toggleComponent = (componentName) => {
    setActiveComponent(componentName);
};

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

var faqData=[
    {que:"1. What is SEO, and why do I need it for my business?",ans:"SEO stands for Search Engine Optimization. It's the process of optimizing your website to rank higher in search engine results pages (SERPs) like Google. When your website ranks higher, it's more likely to be seen by potential customers, leading to increased website traffic, brand awareness, and ultimately, more sales or leads." },
    {que:"2. How long does it take to see results from SEO? ", ans:"SEO is a long-term strategy, and results can vary depending on several factors, including your industry, competition, and the current state of your website. However, with consistent effort and a well-executed SEO strategy, you can typically expect to see noticeable improvements in your rankings and traffic within a few months."},
    {que:"3. What specific SEO services do you offer?  ",ans:"We offer a comprehensive suite of SEO services, including:- (i) Keyword research and analysis (ii) On-page optimization (title tags, meta descriptions, header tags, etc.) ,(iii) Technical SEO (site speed optimization, mobile-friendliness, etc.) , (iv) Off-page optimization (link building, content promotion, etc.) , (v) Local SEO *Content creation and optimization ,(vi) SEO audits and reporting ."},
    {que:"4. How do you track and measure the success of your SEO campaigns? ",ans:"We use various tools and metrics to track the progress of your SEO campaigns, including:- (i)Keyword rankings , (ii)  Organic traffic , (iii)Conversion rates , (iv) Backlinks , (v) Domain authority , We provide regular reports with insights and analysis so you can see the impact of our efforts and make informed decisions about your SEO strategy.  "},
    {que:"5. What is your pricing model for SEO services? ",ans:"Our pricing is customized to fit your unique needs and budget. We offer flexible packages that can be tailored to your specific requirements and goals. Contact us for a free consultation to discuss your options and get a personalized quote. "},
];

    return (
      <div>
          <SEOPage 
                title="SEO Services | Boost Your Rankings with DataMetricks"
                description="DataMetricks provides SEO services designed to improve your website's rankings on search engines, driving more
organic traffic and helping your business reach its full potential online."
                canonicalUrl="https://www.datametricks.com/service/search-engine-optimization-seo"/>

      <Helmet>
             <script type="application/ld+json">
                  {JSON.stringify(serviceSchemaData7)}
             </script>
      </Helmet>
      
        {/* section 1 */}
        <section>
          <Header />
        </section>


        <section className='d-none d-lg-block d-md-block d-sm-none pt-1  '>
      <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Subheader  headings={headings}/>
        </div>
      </section>


             {/* section 2 */}
             <section className="SEOMobileview" style={{marginBottom:'1%',marginTop:'5%'}}>
                    <div className="pb-5 pt-lg-3" style={{backgroundColor:'#00539A',backgroundImage: `url(${Seobg2})`, backgroundRepeat: "no-repeat", height: 'fit-content', backgroundSize: "cover", backgroundPosition: "100% 100%" }}>
                    <div className="container"  >
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <img src={Seobg1} alt="" className="img-fluid pt-4 pt0"  />
                                <img src={Seobg} alt="" className="img-fluid d-none d-sm-none d-md-block d-lg-block " style={{marginLeft:'64px'}} />

                                {/* <h1 style={{fontfamily: "Raleway", fontWeight:"bold", fontSize:'180px', color:'white',position:'absolute',bottom:'17%',left:'11%'}}>SEO</h1> */}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 px-5" style={{paddingTop:'9%'}} >
                                    <h1 className=" font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"extrabold", fontSize:45, color:'white',}}>Dominate Search Results with Expert SEO Services </h1>
                                    <p className="pt-2  font-18" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"regular", fontSize:16, color:'white',}}>Drive Organic Traffic, Increase Visibility, and Grow Your Business with Our Proven SEO Strategies</p>
                                    <button className=" my-3 git-button font-family fw-500 fs-18 Seopara3" onClick={handleShow}>
                                  Get in Touch
                                   </button> 
                                   <Modal show={show} onHide={handleClose} className='modal1'   >
                          <Modal.Header closeButton className='modelheader1' ></Modal.Header>
                            <Modal.Body className='modelbody1'>  
                            <Bigcontact/>
                       </Modal.Body>
       
                          </Modal> 
                                   
                            </div>

                        </div>
                    </div>
                    </div>
            </section>

            {/* Section-3  */}
            <section>
              
                <div className="container mt-10 ">
                <p className="paragraph6  "> Why DataMetricks Is the Best SEO   </p>
                <p className="paragraph6">Services Company  </p>  
                </div>

                <div className='container mt-lg-5 mt-30'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={Seonewimg1} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-4 pt-10' >
                            <p className="pt-3" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We pride ourselves on delivering top-notch SEO services that drive results. Our team of SEO experts employs the latest techniques and strategies to enhance your online visibility, increase organic traffic, and boost your search engine rankings.   </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 order-2 order-lg-1 '> 
                        <div className=' my-lg-5 mx-4'>
                            <p className="pt-3" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We begin with a comprehensive audit of your website to identify areas for improvement and develop a customized SEO plan tailored to your business needs. From keyword research and on-page optimization to link building and content creation, we cover all aspects of SEO to ensure your website performs at its best.   </p>
                        </div>
                        </div>

                        <div className='col-lg-4 order-1 order-lg-2'> 
                            <img src={Seo_newimg2} alt='' className='img-fluid pt-10 '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5 mt-20'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={Seo_newimg3} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 pt-10 mx-4'>
                            <p className="pt-3" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Our data-driven approach and continuous monitoring allow us to adapt and refine our strategies, ensuring sustained growth and maximum ROI. Partner with DataMetricks Consulting to experience the difference that expert SEO services can make for your business.    </p>
                        </div>
                        </div>
                      
                    </div>
                </div>
            </section>


{/* Section - 4  */}


<section>
            <div className="container Seo-Sec-1 my-lg-5 mt-10 shadow bg-white w-100" style={{ borderRadius: 40 ,}}>
                <div className="container pt-lg-5 pt-20 ">
                    <p className="paragraph6"> Boost Your Online Visibility with   </p>
                    <p className="paragraph6">Expert SEO Services  </p>  
                </div>
                <div className="toggle-options d-flex flex-wrap gap-5 justify-content-center pt-5  ">

                    <p onClick={() => toggleComponent('onPageSEO')} style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "semibold", fontSize: 20, cursor:'pointer', color: activeComponent === 'onPageSEO' ? '#00539A' : '#8C8C8C' }}>On-Page SEO</p>
                    <p onClick={() => toggleComponent('technicalSEO')} style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "semibold", fontSize: 20, cursor:'pointer', color: activeComponent === 'technicalSEO' ? '#00539A' : '#8C8C8C' }}>Technical SEO</p>
                    <p onClick={() => toggleComponent('localSEO')} style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "semibold", fontSize: 20, cursor:'pointer', color: activeComponent === 'localSEO' ? '#00539A' : '#8C8C8C' }}>Local SEO </p>
                    <p onClick={() => toggleComponent('contentMarketing')} style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "semibold", fontSize: 20, cursor:'pointer', color: activeComponent === 'contentMarketing' ? '#00539A' : '#8C8C8C' }}>Content Marketing</p>
                    <p onClick={() => toggleComponent('linkBuilding')} style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "semibold", fontSize: 20, cursor:'pointer', color: activeComponent === 'linkBuilding' ? '#00539A' : '#8C8C8C' }}>Link Building</p>
                    
                </div>
                <div className="mt-lg-5">
                    {activeComponent === 'onPageSEO' &&
                        <div className="row " style={{ paddingBottom: '6%' }}>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p className="pt-4 paddingleft paddingright font-18 textcenter" style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "regular", fontSize: 16, paddingLeft: '20%', paddingRight: '6%' }}> Optimize your website's content and structure to improve search engine rankings and enhance user experience. Our on-page SEO services include keyword research, meta tag optimization, content creation, and internal linking strategies to ensure your website is fully optimized for search engines. </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <img src={SEOimg1} alt="" className="img-fluid " style={{ paddingLeft: '18%', paddingRight: '18%' }} />
                            </div>
                        </div>
                    }
                    {/* Add similar blocks for other components */}
                    {activeComponent === 'technicalSEO' &&
                        <div className="row" style={{ paddingBottom: '6%' }}>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p className="pt-4 paddingleft paddingright font-18 textcenter" style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "regular", fontSize: 16, paddingLeft: '20%', paddingRight: '6%' }}> Ensure your website's technical aspects are optimized for search engine crawlers and users. Our technical SEO services address site speed, mobile optimization, XML sitemaps, structured data, and more, to improve your website's performance and crawlability</p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <img src={SEOimg2} alt="" className="img-fluid " style={{ paddingLeft: '18%', paddingRight: '18%', }} />
                            </div>
                        </div>
                    }

                     {/* Add similar blocks for other components */}
                     {activeComponent === 'localSEO' &&
                        <div className="row" style={{ paddingBottom: '6%' }}>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p className="pt-4 paddingleft paddingright font-18 textcenter" style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "regular", fontSize: 16, paddingLeft: '20%', paddingRight: '6%' }}>Optimize your website's content and structure to improve search engine rankings and enhance user experience. Our on-page SEO services include keyword research, meta tag optimization, content creation, and internal linking strategies to ensure your website is fully optimized for search engines. </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <img src={SEOimg3} alt="" className="img-fluid " style={{ paddingLeft: '18%', paddingRight: '18%', }} />
                            </div>
                        </div>
                    }
                      {/* Add similar blocks for other components */}
                      {activeComponent === 'contentMarketing' &&
                        <div className="row" style={{ paddingBottom: '6%' }}>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p className="pt-5 paddingleft paddingright font-18 textcenter"  style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "regular", fontSize: 16, paddingLeft: '20%', paddingRight: '6%' }}>Optimize your website's content and structure to improve search engine rankings and enhance user experience. Our on-page SEO services include keyword research, meta tag optimization, content creation, and internal linking strategies to ensure your website is fully optimized for search engines. </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <img src={SEOimg4} alt="" className="img-fluid " style={{ paddingLeft: '18%', paddingRight: '18%', }} />
                            </div>
                        </div>
                    }
                      {/* Add similar blocks for other components */}
                      {activeComponent === 'linkBuilding' &&
                        <div className="row" style={{ paddingBottom: '6%' }}>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p className="pt-4 paddingleft paddingright font-18 textcenter" style={{ fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: "regular", fontSize: 16, paddingLeft: '20%', paddingRight: '6%' }}>Optimize your website's content and structure to improve search engine rankings and enhance user experience. Our on-page SEO services include keyword research, meta tag optimization, content creation, and internal linking strategies to ensure your website is fully optimized for search engines. </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <img src={SEOimg5} alt="" className="img-fluid " style={{ paddingLeft: '18%', paddingRight: '18%', }} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>




{/* Section-5  */}

<section className="d-none d-sm-none d-md-block d-lg-block">
        <div className="container pt-4 ">
                <p className="paragraph6"> Our Proven SEO Process  </p>
         </div>
        <div className="container mt-4">
            <div className="row" style={{position:'relative'}}>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <img src={Seobg4} alt="" className="img-fluid " style={{paddingLeft:'15%',paddingRight:'15%'}}  />
                        <div className="shadow " style={{borderRadius:'40px',width:'13%',position:'absolute',top:'6%',left:'10%'}}>
                                    <p className="ps-5 pt-2 " style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:17,}}> Initial Audit</p>
                        </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" style={{marginTop:'7%',marginBottom:'7%'}}>
                            <img src={Seobg5} alt="" className="img-fluid justify-content-start " style={{paddingLeft:'5%',paddingRight:'0%'}} />
                            <div className="shadow " style={{borderRadius:'40px',width:'20%',position:'absolute',top:'37%',left:'41%'}}>
                                    <p className="ps-4 pt-2 " style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:17,}}> Strategy Implementation</p>
                        </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                           <img src={Seobg6} alt="" className="img-fluid  "  style={{position:'relative',paddingTop:'50%'}}/>
                           <div className="shadow " style={{borderRadius:'40px',width:'20%',position:'absolute',top:'76%',left:'72%',right:'0%',}}>
                                    <p className="ps-5 pt-2 " style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:17,}}> Ongoing Optimization</p>
                        </div>
                </div>
            </div>
        </div>
</section>

{/* Section-6 */}

     <section>
        <div className="container mt-5 ">
                <p className="paragraph6"> Why Every  Business Needs SEO  </p>
         </div>

         <div className="container ">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-lg-5 pt-20 ps-lg-4  ">
                    <p className="textcenter font-18" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"regular", fontSize:16,}}>Having a strong online presence is crucial for success, and SEO plays a vital role in achieving that. SEO, or Search Engine Optimization, helps improve your website's visibility on search engines, making it easier for potential customers to find you.
                            By optimizing your website's content and structure, SEO drives organic traffic, enhances user experience, and boosts your search engine rankings. This not only increases your brand's credibility but also leads to higher conversion rates and business growth.
                            We understand the importance of SEO for businesses of all sizes. Our expert team employs proven strategies to ensure your website performs at its best, helping you stay ahead of the competition. Invest in SEO to unlock your business's full potential and achieve long-term success. </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 ">
                    <img src={Seobg7} alt="" className="img-fluid px-lg-5 pt-5"/>
                </div>
            </div>
         </div>
     </section>


       {/* Section-7 */}
  
       
    <section className="mt-30 mt0">
             <div>
                <Testimonial1/>
              </div>

        {/* <Testimonial 
             paragraph="We were struggling to get our website noticed before we partnered with Datametricks. Their SEO expertise was a game-changer. Within a few months, we saw a significant increase in organic traffic, and our website now consistently ranks on the first page for our target keywords. We couldn't be happier with the results!" title="Christy Yoland R." subtitle="CEO Founder Loker Indonesia"image={testimonialimage}
             paragraph1="As a local business, it was crucial for us to appear in local search results. Datametricks' local SEO strategy was spot-on. We now dominate local search rankings, and our foot traffic and online inquiries have skyrocketed. They truly understand the nuances of local SEO and have helped us reach our target audience in our community."title1="Christy Yoland R." subtitle1="CEO Founder Loker Indonesia"image1={testimonialimage}
             paragraph2="We had a technically complex website that was hindering our SEO performance. Datametricks' technical SEO team dove deep into our website's structure, identified the issues, and implemented effective solutions. Our site is now faster, more user-friendly, and ranks much higher in search results. Their technical expertise is second to none."title2="Christy Yoland R." subtitle2="CEO Founder Loker Indonesia"image2={testimonialimage}
             paragraph3="Datametricks has been our trusted SEO partner for over a year. They've consistently delivered outstanding results, helping us maintain top rankings and drive organic traffic to our website. Their team is always responsive, proactive, and goes above and beyond to ensure our success. We highly recommend them for any business looking to improve their online visibility."title3="Christy Yoland R." subtitle3="CEO Founder Loker Indonesia"image3={testimonialimage}
             paragraph4="Datametricks has been our trusted SEO partner for over a year. They've consistently delivered outstanding results, helping us maintain top rankings and drive organic traffic to our website. Their team is always responsive, proactive, and goes above and beyond to ensure our success. We highly recommend them for any business looking to improve their online visibility."title4="Christy Yoland R." subtitle4="CEO Founder Loker Indonesia"image4={testimonialimage}

             /> */}
      
      </section>

      <section className='mt-lg-4 mt-10'>
<div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>
  <div className='mt-4'>
  <Blog1 categoryId={categoryId}/>
  </div>
</section>

    <section>
      <div className="container">
            <div className="mx-lg-5 my-5">
              <Minicontact />
            </div>
          </div>
          </section>



    <section>
            <div className="mb-5">
            <Faq  faqData={faqData}/>
         </div>
    </section>


    <section>
  <div>
    <Chatbox/>
  </div>
</section>

<section>
<Footer />
</section>
</div>
);
};
export default Seo;