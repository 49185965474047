import Chatbox from "../Components/Chatbox";
import Header from "../Components/Header";
import Footer from '../Components/footer';
import SEOPage from "../Components/SEOPage";
import Subheader from "../Components/Subheader";
import React, { useState, useEffect } from 'react';

function Teams (){

      // ================= Subheader hide and show start ========================

      const [isScrollingDown, setIsScrollingDown] = useState(false);
      let lastScrollTop = 0;
    
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
        if (scrollTop > lastScrollTop) {
          // Scrolling down, hide the header
          setIsScrollingDown(true);
        } else {
          // Scrolling up, show the header
          setIsScrollingDown(false);
        }
    
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
      };
    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    
      // ================= Subheader hide and show End ========================
    
    
    const headings = [
    
        {
            head:'Our Team',urllink:'/our-team'
            },
      {
        head:'Web Development',urllink:'/service/website-design-development-solutions'
        },
      {
        head:'Custom Website',urllink:'/service/custom-website-design-development-solutions'
        },
      {
        head:'Graphic Designing',urllink:'/services/graphic-desinging'
        },
      {
        head:'Digital Marketing',urllink:'/services/digital-marketing'
        },
      {
          head:'SEO',urllink:'/service/search-engine-optimization-seo'
     },
      {
          head:'Social Media Marketing',urllink:'/service/social-media-marketing'
     },
     {
      head:'Performance Marketing',urllink:'/services/performance-marketing'
    },
    {
    head:'Mobile App Development',urllink:'/service/mobile-app-design-development-solutions'
    },
    {
    head:'E Commerce Website',urllink:'/service/ecommerce-website-design-development-solutions'
    },
    {
    head:'CMS Website',urllink:'/service/cms-website-design-development-solutions'
    },
    
    ];

const teamsbgimg = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teamsbgimg.svg';
const teamsicon1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teamsicon-1.svg';
const teamsicon2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teamsicon-2.svg';
const teamsicon3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teamsicon-3.svg';
const teamsimg1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img1.svg';
const teamsimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img2.svg';
const teamsimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img3.svg';
const teamsimg4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img4.svg';
const teamsimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img5.svg';
// const teamsimg6 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img6.svg';
const teamsimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img7.svg';
const teamsimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img8.svg';
const teamsimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img9.svg';
const teamsimg10 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/rajesh2.jpeg'
const teamsimg11 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img11.svg';
const teamsimg12 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img12.svg';
// const teamsimg13 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/prashant1.jpeg';
const teamsimg14 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img14.svg';
const teamsimg15 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img15.svg';
const teamsimg16 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teams-img16.svg';
const teamslinkedicon = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/teamslinkedinicon.svg';
const prashant = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/prashantteam.svg";
// const rajesh = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/rajeshteam.svg";
const arushi = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/arushi.svg";
const priyanka = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/priyanka.svg";
  
    
return(
    <div>
       <SEOPage 
                title="Meet the Datametricks Team | Experts in Website Design & Digital Marketing"
                description="Get to know the talented team behind Datametricks. Our experienced professionals are
committed to delivering exceptional website design, mobile app development, and digital
marketing services."
                canonicalUrl="https://www.datametricks.com/our-team"
            />
        <div>
            <Header/>
        </div>

        <section className='d-none d-lg-block d-md-block d-sm-none pt-1  '>
      <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Subheader  headings={headings}/>
        </div>
      </section>

        <section className="persec-2" style={{marginTop:'12%'}}>
            <div className="container">
                <div className="row text-center">
                    <div className="col-lg-12">
                        <img src={teamsbgimg} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>

        <section className="mt-4">
  <div>
    <p className="fw-600 fs-36 plus text-center font-25">Our Values</p>
  </div>
  <div className="container-fluid p-10" style={{border:'1px solid rgba(221, 221, 221, 1)',borderRadius:'13px'}}>
  <div className="container mt-3" >
    <div className="row p-lg-4 textcenter">
      <div className="col-12 col-md-4 mb-4 mb-lg-0">
        <div className="contact1 text-md-left" style={{paddingLeft:'15%'}}>
          <img src={teamsicon3} alt="" />
          <p className="fw-600 fs-18 plus mb-0 pt-4">Problem Solving</p>
          <p className="fs-15 plus pt-1" style={{color:'#8C959F'}}>Providing solutions to common challenges faced by developers and marketers, such as troubleshooting coding issues, optimizing campaigns, etc.</p>
        </div>
      </div>
    
      <div className="col-12 col-md-4 mb-4 mb-lg-0">
        <div className="contact1 text-md-left" style={{paddingLeft:'20%'}}>
          <img src={teamsicon2} alt="" />
          <p className="fw-600 fs-18 plus mb-0 pt-4">Practical Guidance</p>
          <p className="fs-15 plus pt-1" style={{color:'#8C959F'}}>Offering actionable tips, tutorials, and guides that help users improve their skills and knowledge in these fields.</p>
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="contact1 text-md-left" style={{paddingLeft:'20%'}}>
          <img src={teamsicon1} alt="" />
          <p className="fw-600 fs-18 plus mb-0 pt-4">Innovation and Creativity</p>
          <p className="pt-1 fs-15 plus" style={{color:'#8C959F'}}>Showcasing innovative solutions, case studies, and examples of successful campaigns to inspire creativity and problem-solving.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>

<section>
  <div className="container text-center mt-5">
    <p className="plus fs-35 font-23 fw-600 mb-0">A diverse team united to reform    </p>
    <p className="plus fs-35 font-23 fw-600">Indian healthcare delivery. </p>
    <button type="button" class="btn pt-2  btn-sm pb-0 mt-2 " style={{backgroundColor:'rgba(232, 244, 255, 1)',boxShadow:'none',border:'none',outline:'none',borderRadius:'11px',paddingLeft:'5%',paddingRight:'5%'}}><p className="fs-22 fw-500 plus font-18" style={{color:'rgba(0, 66, 126, 1)'}}>Finding Team</p></button>
  </div>
</section>


<section>
  <div className="p-lg-5  mt-lg-5 mt-20 teamsimagecard" style={{ backgroundColor: 'rgba(249, 249, 249, 1)' }}>
    <div className="container">
      <div className="row justify-content-center teamsimagecard3">
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg1} alt="Vivek Singh" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Vivek Singh</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Director</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg16} alt="Mihir Ghoswami" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Sundeep Pandey</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Regional Manager</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1 p-1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg10} alt="" className="img-fluid w-100 "  />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Rajesh Pandey</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Backend Developer</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg4} alt="Priyanshu Dubey" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Priyanshu Dubey</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Application Developer</p>
          <a href="https://www.linkedin.com/in/priyanshu-dubey-945a60171"><img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center teamsimagecard3 mt-3">
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 " style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={prashant} alt="" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2 ">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Prashant Raj </h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Quality Analyst</p>
           <a href="https://www.linkedin.com/in/prashant-raj-692544186?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">   <img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg14} alt="Mihir Ghoswami" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Ravi Chaudhary</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Devops Engineer</p>
           <a href="https://www.linkedin.com/in/ravi-chaudhary-418b74276?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" >   <img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg15} alt="Sakshi Sharma" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Ankit Kumar</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Frontend Developer</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg2} alt="Priyanshu Dubey" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Mihir Goswami</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Marketing Manager</p>
          <a href="https://www.linkedin.com/in/mihirgoswami/">   <img src={teamslinkedicon} alt="LinkedIn Icon" /></a> 
            </div>
          </div>
        </div>
      </div>
    

      <div className="row justify-content-center teamsimagecard3 mt-3">
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg9} alt="Vivek Singh" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Sandeep Kumar </h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Application Developer</p>
            <a href="https://www.linkedin.com/in/sandeep-kumar-ba4750195?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">  <img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg3} alt="Mihir Ghoswami" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Sakshi Sharma</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>HR</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg11} alt="Sakshi Sharma" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Imtiyaj</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Backend Developer</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg12} alt="Priyanshu Dubey" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Kritesh Singh</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Frontend Developer</p>
           <a href="https://www.linkedin.com/in/kritesh-singh-a1a009212?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">   <img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
      </div>

    

      <div className="row justify-content-center teamsimagecard3 mt-3">
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg5} alt="Vivek Singh" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Harsh Rohilla </h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>UI/UX Designer</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={arushi} alt="Mihir Ghoswami" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Arushi Rathore</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Graphic Designer</p>
              <img src={teamslinkedicon} alt="LinkedIn Icon" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg7} alt="Sakshi Sharma" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Yashpal Singh</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Graphic Designer</p>
          <a href="https://www.linkedin.com/in/yash-king-6b315879/">    <img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={teamsimg8} alt="Priyanshu Dubey" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Priyanka Rastogi</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>CRM</p>
          <a href="https://www.linkedin.com/in/priyanka-rastogi-92928a231?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">    <img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row  teamsimagecard3 mt-3">
      <div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
          <div className="pb-3 teamsimagecard1" style={{ boxShadow: '0px 0px 5px rgba(150, 150, 150, 0.25)', borderRadius: '7px' }}>
            <img src={priyanka} alt="Priyanshu Dubey" className="img-fluid w-100" />
            <div className="ps-3 teamsimagecard2">
              <h2 className="fs-18 fw-600 plus pt-3 font-18">Priyanka Negi</h2>
              <p className="fw-500 fs-15 plus" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Social Media Executive</p>
          <a href="https://www.linkedin.com/in/priyanka-rastogi-92928a231?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">    <img src={teamslinkedicon} alt="LinkedIn Icon" /></a>
            </div>
          </div>
        </div>
      
      </div>
     
    </div>
  </div>
</section>




<section>
  <div>
    <Chatbox/>
  </div>
</section>
      

     
        <section className="">
            <Footer/>
        </section>
    </div>
)
}
export default Teams;